<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "App",
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
  mounted() {
    // if (!this._isMobile()) {
    //   window.location.href = 'http://dachengoffer.com/';
    // }
  },
};
</script>

<style>
body {
  width: 750px;
}
.test {
  font-size: 22px;
  height: 335px;
  background-color: aqua;
}
</style>
