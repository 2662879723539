<template>
  <div class="nav">
    <div class="nav-tabs">
      <div
        v-for="tab in tabList"
        :key="tab.index"
        @click="changeRouter(tab)"
        :class="tab.index === selectTabIndex ? 'tab-items selected' : 'tab-items'"
      >{{ tab.name }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyHeader",
  created() {
    this.tabList.forEach((tab) => {
      if (tab.path == this.$route.path) this.selectTabIndex = tab.index;
    });
  },
  methods: {
    changeLang() {
      let locale = localStorage.getItem("language") || "zh";
      let temp = locale === "zh" ? "en" : "zh";
      this.$i18n.locale = localStorage.language = temp; //改变当前语言
    },
    changeRouter(tab) {
      // console.log(tab);
      this.selectTabIndex = tab.index;
      this.$router.push(tab.path);
    },
  },
  data() {
    return {
      selectTabIndex: 0,
      tabList: [
        {
          index: 1,
          name: "竟乘留学",
          path: "/journeyon-abroad",
        },
        {
          index: 2,
          name: "竟乘求职",
          path: "/journeyon-offer",
        },
      ],
    };
  },
};
</script>

<style lang="less">
.nav {
  display: flex;
  align-items: center;
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 88px;
  background-color: #232323;
  .nav-tabs {
    color: #858383;
    padding-left: 150px;
    display: flex;
    .tab-items {
      width: fit-content;
      line-height: 88px;
      height: 88px;
      margin-right: 100px;
      font-size: 30px;
      font-weight: normal;
      user-select: none;
    }
    .selected {
      color: #fff;
      border-bottom: 6px solid #428dfe;
    }
    .tab-items:hover {
      border-bottom: 6px solid #428dfe;
      transition: 0.1s all linear;
    }
  }
  .nav-language {
    img {
      width: 30px;
    }
    width: 100px;
    cursor: pointer;
    position: absolute;
    right: 0;
    span {
      user-select: none;
      font-size: 15px;
      padding-left: 10px;
    }
  }
}
</style>