import Vue from 'vue'
import VueRouter from 'vue-router'
import MyHome from '@/pages/MyHome'
import MyOffer from "@/pages/offer/Offer";
import MyAbroad from "@/pages/abroad/Abroad";
// import journeyonPrivacy from '../pages/journeyon-privacy'

// 获取原型对象push函数
const originalPush = VueRouter.prototype.push

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location){
return originalPush.call(this , location).catch(err=>err)
}

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location){
return originalReplace.call(this , location).catch(err=>err)
}

Vue.use(VueRouter)

export default new VueRouter({
  mode: 'history',
  routes:[
    {
      path: '/',
      component: MyHome,
      redirect: '/journeyon-abroad',
      children:[
        {
          path: 'journeyon-abroad',
          component: MyAbroad,
          meta: {
            title: '竟乘'
          }
        },
        {
          path: 'journeyon-offer',
          component: MyOffer,
          meta: {
            title: '竟乘'
          }
        },
      ]
    },
    {
      path:'*',
      redirect:'/'
    }
  ]
})