<template>
  <!-- Footer -->
  <div class="footer">
    <div class="footer-content">
      <div class="footer-logo">
        <img
          src="@/assets/img/07-底部信息/logo.png"
          alt=""
        >
      </div>
      <div class="footer-text">
        北京竟乘管理咨询服务有限公司<br>
        地址：北京市海淀区知春路113号1栋16层1904<br>
        京ICP备2022029023号<br>
        京公网安备11010502054272号
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeFooter",
  data(){
    return{
      domain: window.location.hostname
    }
  }
};
</script>

<style lang="less" scoped>
.footer {
  background-color: #0d2749;
  width: 100%;
  img {
    display: block;
    margin: 0 auto;
  }
  .footer-content {
    margin: 0 auto;
    width: 750px;
    display: flex;
    height: 230px;
    .footer-logo {
      img {
        width: 205px;
        height: 37px;
      }
      padding-left: 80px;
      padding-top: 95px;
    }
    .footer-text {
      display: flex;
      align-items: center;
      line-height: 36px;
      color: #dddedf;
      font-size: 18px;
      padding: 0 82px 0 55px;
    }
  }
}
</style>