<template>
  <div v-show="$store.state.qRCodeStatus" class="serviceQRcodeWrap">
    <div @click="hideQRcode" class="closeQRcode">
    </div>
    <div class="addTips">
      微信扫码添加小助手
    </div>
    <div class="serviceQRcode">
      <img src="@/assets/img/08-弹窗/二维码.png">
    </div>
    <div class="knowMore">
      了解更多课程详情、优惠信息、申领免费资料
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ServiceQRcode',
    methods:{
      hideQRcode(){
        this.$store.commit('HIDEQRCODE');
      }
    }
  }
</script>

<style lang="less">
  .serviceQRcodeWrap{
    position: relative;
    z-index: 300;
    height: 400px;
    width: 500px;
    background: url('@/assets/img/08-弹窗/弹窗背景.png');
    border-radius: 30px;
    position: fixed;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    overflow: hidden;
    .closeQRcode{
      width: 50px;
      height: 30px;
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 0;
      background: url('@/assets/img/08-弹窗/关闭-按钮.png');
      background-size: cover;
    }
    .addTips{
      user-select: none;
      font-size: 24px;
      color: #fff;
      width: fit-content;
      padding-top: 28px;
      margin: 0 auto;
      font-weight: 400;
    }
    .serviceQRcode{
      padding-top: 60px;
      margin: 0 auto;
      width: fit-content;
      img{
        border-radius: 15px;
        width: 200px;
        height: 200px;
      }
    }
    .knowMore{
      user-select: none;
      font-size: 18px;
      color: #fff;
      width: fit-content;
      padding-top: 25px;
      margin: 0 auto;
      font-weight: 400;
    }
  }
</style>